<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              Milestone Stages
            </h3>
          </b-card-header>

          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              class="form-inline"
              label="Search"
              label-size="sm"
            >
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search reviews"
                type="text"
              />
              <b-button
                v-b-modal.applicationModal
                variant="primary"
              >
                <feather-icon icon="PlusIcon" />
                Add Stage
              </b-button>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'fullName'"
                class="text-nowrap"
              >
                <b-avatar
                  :src="props.row.avatar"
                  class="mx-1"
                />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    dropright
                    no-caret
                    size="sm"
                    toggle-class="text-decoration-none"
                    variant="link"
                  >
                    <template #button-content>
                      <feather-icon
                        class="text-body align-middle"
                        icon="MoreVerticalIcon"
                        size="16"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{name: 'startups-review-questions',
                            params: {id: $route.params.id,
                                     sid:props.row.id,},
                            query: {readonly: 'true'}}"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="EyeIcon"
                      />
                      <span>View Stages</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="{name: 'startups-review-evaluations',params: {id:$route.params.id,
                                                                         sid: props.row.id,
                      }}"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="CheckCircleIcon"
                      />
                      <span>View Evaluations</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="selectedRow=props.row.originalIndex; $bvModal.show('recommendationModal')"
                    ><feather-icon
                       class="mr-50"
                       icon="BarChartIcon"
                     />
                      <span>Update Status</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
      <b-modal
        id="applicationModal"
        ok-only
        ok-title="Submit"
        size="lg"
        title="Review Form"
        @ok="addReview"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Title"
              label-size="sm"
            >
              <b-form-input
                v-model="reviewTitle"
                placeholder="Review Title"
                required
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Type"
              label-size="sm"
            >
              <b-form-input
                v-model="reviewType"
                placeholder="Review Type"
                required
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <form-builder
          v-model="applicationForm"
          :inherited-sections="applicationForm"
        />
      </b-modal>
      <b-modal
        v-if="rows[selectedRow]"
        id="recommendationModal"
        no-close-on-backdrop
        ok-only
        ok-title="Update"
        title="Recommendations"
        @ok="updateStatus"
      >
        <b-form-group
          label="Recommendations"
        >
          <b-form-input
            v-model="rows[selectedRow].programs_assignmentscorerecords[0].recommendation"
            placeholder="Your recommendation for startup"
          />
        </b-form-group>
        <b-form-group
          label="Verdict"
        >
          <v-select
            v-model="rows[selectedRow].programs_assignmentscorerecords[0].verdict"
            :options="['Excellent', 'Good', 'Satisfactory', 'Unsatisfactory', 'Incomplete']"
            placeholder="Select from list"
          />
        </b-form-group>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    vSelect,
    FormBuilder,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Recommendation',
          field(row) {
            return row.programs_assignmentscorerecords[0].recommendation || '-'
          },
        },
        {
          label: 'Verdict',
          field(row) {
            return row.programs_assignmentscorerecords[0].verdict || '-'
          },
        },
      ],
      rows: [],
      searchTerm: '',
      reviewTitle: null,
      reviewType: null,
      applicationForm: null,
      selectedRow: 0,
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      mutationLoading: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  methods: {
    addReview() {
      this.mutationLoading = true
      this.$apollo.mutate(
        {
          mutation: gql`mutation($data: [programs_assignmentquestionstable_insert_input!]!) {
                        insert_programs_assignmenttable_one(object: {title: "${this.reviewTitle}",type: "${this.reviewType}", progress_stage_id: ${this.$route.params.mid}, programs_assignmentquestionstables: {data: $data}}) {
                          id
                        }
                      }`,
          variables: {
            data: this.applicationForm,
          },
          update: () => {
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        },
      )
      this.reviewTitle = null
      this.applicationForm = null
      this.reviewType = null
    },
    updateStatus() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($object: programs_assignmentscorerecord_insert_input!) {
          insert_programs_assignmentscorerecord_one(object: $object, on_conflict: {constraint: programs_assignmentscorerecord_pkey, update_columns: [verdict, recommendation]}) {
            id
          }
        }`,
        variables: {
          object: this.rows[this.selectedRow].programs_assignmentscorerecords[0],
        },
        update: (store, { data: { insert_programs_assignmentscorerecord_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_assignmentscorerecord_one.id ? 'Updated successfully' : 'Failed to update',
              icon: insert_programs_assignmentscorerecord_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_assignmentscorerecord_one.id ? 'success' : 'warning',
            },
          })
          this.mutationLoading = false
        },

      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_assignmenttable(where: {progress_stage_id: {_eq: ${this.$route.params.mid}}})  {
            id
            title
            type
            programs_assignmentscorerecords {
              id
              verdict
              recommendation
            }
          }
        }`
      },
      update(data) {
        data.programs_assignmenttable.forEach(e => {
          if (e.programs_assignmentscorerecords.length < 1) {
            e.programs_assignmentscorerecords.push({
              recommendation: '',
              verdict: '',
              assignment_id: e.id,
            })
          }
        })
        return data.programs_assignmenttable
      },
    },
  },
}

</script>
